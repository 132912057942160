import { Button, Col, Row, Space } from "antd";
import styles from "./styles.module.css";
import React, { useMemo } from "react";
import homeStyles from "../../Home/styles.module.css";
import Logo from "../../Home/HomeComponents/Logo";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { PurchaseLimitation, useSettings } from "../../Networking/useSettings";
import { useMe } from "../../AppSetup/MeContext";
import { useTranslation } from "react-i18next";
import Order from "../../Order/Order";
import { useUnlimitedCap } from "./useUnlimitedCap";
import { useRecentOrders } from "../../Hooks/useRecentOrders";
import { useIntercom } from "../../Hooks/useIntercom";

const UnlimitedRecap = () => {
  const { t } = useTranslation();
  const { purchaseLimitation } = useSettings();
  const me = useMe();
  const navigate = useNavigate();
  const { orderedDishes, maxDishes } = useUnlimitedCap();
  const handleIntercomSupportClick = useIntercom();

  const today = useMemo(() => dayjs(), []);

  const { recentOrders } = useRecentOrders();

  const renderHome = () => {
    return (
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <Row style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
          <Logo small />
          <div className={homeStyles.headerTitle}>
            <h1>{t("Benvenuto")}</h1>
          </div>
        </Row>
      </Space>
    );
  };

  const renderOrders = () => {
    return (
      <Row justify="center">
        <Col span={8} xs={24} md={12} xl={8} style={{ padding: "16px 16px 0" }}>
          <div
            className={homeStyles.banner + " " + homeStyles.bannerBox}
            style={{ paddingBottom: 16 }}
          >
            <img
              className={homeStyles.orderIcon}
              src={"/icons/delivery.svg"}
              alt={"delivery truck icon"}
            />
            <h1
              className={homeStyles.titleSmaller}
              style={{ width: "80%", marginBottom: 32 }}
            >
              {t("Le tue box in arrivo:")}
            </h1>
            {recentOrders?.length === 0 ? (
              <div>{t("Nessuna box in arrivo")}</div>
            ) : null}
            {recentOrders
              ?.sort((order1, order2) => {
                return dayjs(order1.createdAt).isAfter(dayjs(order2.createdAt))
                  ? 1
                  : -1;
              })
              .map((order) => <Order order={order} />)}
          </div>
        </Col>
      </Row>
    );
  };

  const renderInfoOnEligibilityToOrder = () => {
    return (
      <>
        {me.latestOrder &&
          !me.latestOrder.cancelledAt &&
          dayjs(me.latestOrder.expectedShippingDate) > today ? (
          <Row justify="center">
            <Col span={8} xs={24} md={12} xl={8} style={{ padding: "0 16px" }}>
              <div className={styles.noteWithIcon} style={{ marginTop: 16 }}>
                <img src={"/icons/alert.svg"} alt={"attention"} />
                {t(
                  "Potrai effettuare un altro ordine dopo aver ricevuto la box in consegna il ",
                )}
                {me.latestOrder?.expectedShippingDate}
              </div>
            </Col>
          </Row>
        ) : (
          <Row justify="center">
            <Col
              span={8}
              xs={24}
              md={12}
              xl={8}
              style={{ padding: "16px 16px 0px 16px" }}
            >
              <Button
                className={styles.button + " " + styles.yellow}
                onClick={() => {
                  navigate("/shop/edit");
                }}
                style={{ width: "100%" }}
              >
                {t("Ordina")}
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const renderWarning = () => {
    return (
      <Row justify="center">
        <Col
          span={8}
          xs={24}
          md={12}
          xl={8}
          style={{ padding: "0 16px 0 16px" }}
        >
          <div
            className={homeStyles.banner + " " + styles.bannerFlex}
            style={{
              marginTop: 16,
              marginBottom: 16,
              backgroundColor: "var(--light-green)",
              color: "var(--green)",
            }}
          >
            <div style={{ padding: 24 }}>
              <h1
                className={homeStyles.titleSmaller}
                style={{
                  fontSize: 18,
                  display: "flex",
                  gap: 4,
                  marginBottom: 8,
                }}
              >
                <img
                  src={"/icons/alert_small_green.svg"}
                  alt={"alert icon"}
                  style={{ marginTop: 2 }}
                />
                {t("Attenzione")}
              </h1>
              {t(
                "Una box occupa un intero cassetto! Controlla sempre di avere spazio nel freezer prima di ordinare.",
              )}
            </div>
            <div className={styles.bannerPhoto}></div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderHelp = () => {
    return (
      <Row justify="center">
        <Col
          span={8}
          xs={24}
          md={12}
          xl={8}
          style={{ padding: "0 16px 16px 16px" }}
        >
          <div className={homeStyles.banner + " " + homeStyles.bannerBox}>
            <h1
              className={homeStyles.titleSmaller}
              style={{ fontSize: 18, marginBottom: 8 }}
            >
              {t("Dubbi?")}
            </h1>
            {t(
              "Contattaci, e faremo del nostro meglio per rispondere ad ogni domanda.",
            )}
            <br />
            <br />
            <Button
              onClick={() => handleIntercomSupportClick ? handleIntercomSupportClick() : navigate("/support")}
              className={styles.button}
            >
              {t("Contatta l'assistenza")}
            </Button>
          </div>
        </Col>
      </Row>
    );
  };

  const renderUnlimitedCap = () => {
    return (
      <Row justify="center">
        <Col
          span={8}
          xs={24}
          md={12}
          xl={8}
          style={{ padding: "0 16px 16px 16px" }}
        >
          <div
            className={homeStyles.banner + " " + homeStyles.bannerBox}
            style={{
              marginTop: 16,
              backgroundColor: "var(--light-green)",
              justifyContent: "space-between",
            }}
          >
            <h1
              className={homeStyles.titleSmaller}
              style={{ textAlign: "center", marginBottom: 16 }}
            >
              {t("Piatti ordinati nel mese corrente:")}
            </h1>
            <div className={styles.listPosition}>
              {orderedDishes} / {maxDishes}
            </div>
          </div>
          <div className={homeStyles.banner + " " + homeStyles.bannerBox}>
            <div className={styles.listItem}>
              <img src={"/icons/cart_icon.svg"} alt={"check"} />
              <div>
                {t(
                  "Ricorda: tutti i pasti devono essere consegnati entro la fine del periodo corrente. Gli ordini con consegna nei periodi successivi utilizzeranno i crediti dei periodi successivi.",
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {renderHome()}
      {renderOrders()}
      {purchaseLimitation ===
        PurchaseLimitation.CannotOrderBeforePreviousBoxDelivery
        ? renderInfoOnEligibilityToOrder()
        : null}
      {maxDishes ? renderUnlimitedCap() : null}
      {renderWarning()}
      {renderHelp()}
    </>
  );
};

export default UnlimitedRecap;
