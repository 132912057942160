import { useSettings } from "../Networking/useSettings";

const openIntercomMessanger = () => {
  if (window.Intercom) {
    window.Intercom("show");
  } else {
    console.error("Intercom is not initialized.");
  }
};

export const useIntercom = () => {
  const { intercomChatEnabled } = useSettings();

  return intercomChatEnabled ? openIntercomMessanger : undefined;
};
